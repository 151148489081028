import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/poradnik/#dzieci"> &gt; Poradnik(Dzieci)</a>
            <a
              href="/poradnik/dzieci/walka_z_infekcjami_-_katar_i_kaszel_u_dziecka_/"
              className="sel"
            >
              {" "}
              &gt; Walka z infekcjami - katar i kaszel u dziecka{" "}
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/shutterstock_494479543.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Walka z infekcjami - katar i kaszel u dziecka </h1>
              <div className="wysiwyg">
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <b>
                    O poranku dziecko wychodzi z domu uśmiechnięte i zadowolone.
                    Powrót wygląda mniej optymistycznie. Rumieńce na twarzy,
                    wodnisty katar, stan podgorączkowy, ogólne rozbicie i
                    osłabienie. Rozwija się infekcja, a organizm dziecka zaczyna
                    z nią walczyć. Pierwszy krok, który należy podjąć to
                    złagodzenie dolegliwości.&nbsp;
                  </b>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Początkowe symptomy wirusowego zakażenia górnych dróg
                    oddechowych bardzo często uznajemy za coś błahego. Tymczasem
                    może ono prowadzić do stanów zapalnych (np. zapalenia ucha,
                    krtani, oskrzeli czy płuc), a także spowodować konieczność
                    zastosowania kuracji antybiotykowej. Katar i kaszel są
                    dolegliwościami, które wskazują na zakłócenia w poprawnym
                    funkcjonowaniu układu oddechowego. Pojawiają się w przeciągu
                    od 48 do 72 godzin, od momentu kontaktu z wirusem.
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <img
                    alt="ACTIFED ® Syrop na kaszel i na katar dla dzieci o smaku jeżynowym."
                    src="/web/kcfinder/uploads/files/shutterstock_94426129.jpg"
                    style={{
                      width: "380px",
                      height: "253px",
                      "border-width": "2px",
                      "border-style": "solid",
                      float: "left",
                      margin: "5px 10px 10px 0px",
                    }}
                  />
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  Pierwszy sygnał rozpoczynającej się infekcji to ogólne
                  rozdrażnienie. Dziecko jest zmęczone, marudne, ospałe, do tego
                  ma szkliste oczy, odczuwa ciągłe pragnienie i ma stan
                  podgorączkowy. Taka reakcja to pozytywny objaw – organizm daje
                  znak, że rozpoczął walkę z wirusami. Z powodu stanu zapalnego
                  zwiększa się też ukrwienie tkanek, dzięki czemu komórki
                  obronne szybciej docierają do źródła infekcji, a wirusy, za
                  sprawą podwyższonej temperatury, są niszczone. By wspomóc
                  organizm w zmaganiach z infekcją konieczne jest pozostanie w
                  domu i odpoczynek. W tej sytuacji sprawdzi się także czosnek z
                  ciepłym mlekiem i/lub miodem, który działa przeciwbakteryjnie
                  i przeciwgrzybiczo.
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <div>&nbsp;</div>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Podczas infekcji dziecku towarzyszy również katar. W
                    pierwszym etapie jest on wodnisty, a następnie zmienia się w
                    gęsty i śluzowaty. Pojawia się także{" "}
                    <a
                      href="/slowniczek/#obrzek_blony_sluzowej"
                      target="_blank"
                    >
                      obrzęk błony śluzowej
                    </a>
                    . Zablokowany nos oraz zalegająca w nim{" "}
                    <a href="/slowniczek/#wydzielina_z_nosa" target="_blank">
                      wydzielina
                    </a>{" "}
                    powodują problemy z oddychaniem, przeszkadzające w
                    codziennych aktywnościach, przyczyniając się do
                    nieprzespanych nocy. Najlepszą metodą walki z katarem jest
                    regularne oczyszczanie nosa dziecka i usuwanie z niego
                    wydzieliny, oraz picie dużej ilości płynów (nawadniając
                    organizm, nawilżamy nos). W złagodzeniu objawów może też
                    pomóc{" "}
                    <a
                      href="/produkty/sudafed_xylospray_dla_dzieci/"
                      target="_blank"
                    >
                      Sudafed<sup>®</sup> XyloSpray
                    </a>
                    &nbsp; lub{" "}
                    <a
                      href="/produkty/sudafed_xylospray_ha_dla_dzieci/"
                      target="_blank"
                    >
                      Sudafed<sup>®</sup> Xylospray HA
                    </a>{" "}
                    dla dzieci, które zawierają chlorowodorek{" "}
                    <a href="/slowniczek/#ksylometazolina" target="_blank">
                      ksylometazoliny
                    </a>
                    , dzięki czemu działają obkurczająco na naczynia krwionośne,
                    minimalizują obrzęk oraz przekrwienie błony śluzowej nosa,
                    zapobiegają zaleganiu wydzieliny w trąbce słuchowej i
                    ułatwiają jej odpływ, ułatwiając dziecku oddychanie.{" "}
                    <a
                      href="/produkty/sudafed_xylospray_dla_dzieci/"
                      target="_blank"
                    >
                      Sudafed<sup>®</sup> Xylospray
                    </a>{" "}
                    dla dzieci działa nawet do dziesięciu godzin, zapewniając
                    spokojny sen nie tylko dla dziecka, ale także dla rodziców.{" "}
                    <a
                      href="/produkty/sudafed_xylospray_ha_dla_dzieci/"
                      target="_blank"
                    >
                      Sudafed<sup>®</sup> Xylospray HA
                    </a>{" "}
                    dzięki braku konserwantów nie podrażnia błon śluzowych w
                    delikatnych noskach dzieci.
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Podczas zmagania się z infekcją, dziecku bardzo często
                    towarzyszy także kaszel. Drapanie w gardle to znak, że w
                    drogach oddechowych pojawił się stan zapalny (w wyniku
                    przedostania się wirusów), a błona śluzowa gardła uległa
                    podrażnieniu. Dzieci mogą zmagać się zarówno z kaszlem
                    suchym, jak i mokrym. Kaszel suchy częściej pojawia się na
                    początku choroby. Podczas jednoczesnego występowania kataru
                    i suchego kaszlu, warto zastosować jeden preparat, który
                    eliminuje oba objawy. Można wtedy sięgnąć np. po syrop{" "}
                    <a
                      href="/produkty/actifed_syrop_na_katar_i_kaszel/"
                      target="_blank"
                    >
                      Actifed
                    </a>
                  </span>
                  <a
                    href="/produkty/actifed_syrop_na_katar_i_kaszel/"
                    target="_blank"
                  >
                    <span className="s2">
                      <sup>®</sup>
                    </span>
                  </a>
                  <span className="s1">
                    , który odblokowuje nos oraz łagodzi suchy kaszel. Przy
                    kaszlu mokrym (w zaawansowanym stadium przeziębienia)
                    zalegający w górnych drogach oddechowych&nbsp; śluz jest
                    wilgotny, gęsty i trudny do odkrztuszenia. Warto wtedy
                    sięgnąć po leki o działaniu wykrztuśnym.
                  </span>
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Gdy dziecko zmaga się z infekcją, ma kaszel, a temperatura
                    ciała przekracza 38,5 stopni C, należy podać leki przeciw
                    gorączkowe. Sprawdza się wtedy również chłodna kąpiel i
                    zimne okłady. Stosujemy je po to, by organizm mógł oddać
                    ciepło otoczeniu. Ważne jest także podawanie dużej ilości
                    płynów. Gorączka jest niekorzystna dla wewnętrznych
                    narządów, układu nerwowego i krążenia, dlatego wysoka
                    temperatura to sygnał, aby udać się do lekarza na
                    konsultację.&nbsp;
                  </span>
                </p>
                <p className="p2">&nbsp;</p>
                <p className="p4">
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    <span className="s1">Bibliografia:&nbsp;</span>
                  </span>
                </p>
                <ol className="ol1">
                  <li className="li1">
                    <span
                      style={{
                        "font-size": "11px",
                      }}
                    >
                      <span className="s1">
                        Samoliński, <i>Przewlekłe nieżyty nosa</i>, [w:]
                        „Alergia”, 2013, nr 4, s. 49-55.
                      </span>
                    </span>
                  </li>
                  <li className="li1">
                    <span
                      style={{
                        "font-size": "11px",
                      }}
                    >
                      <span className="s1">
                        Kasperska-Zając,{" "}
                        <i>Kaszel w praktyce klinicznej -&nbsp; część I</i>,
                        [w:] „Alergia”, 2014, nr 3, s. 4-7.
                      </span>
                    </span>
                  </li>
                </ol>
              </div>
              <div className="other_article">
                <p className="name">Podobne artykuły</p>
                <a
                  href="/poradnik/dzieci/zatkany_nos_u_dziecka"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/pokonaj_zatkany_nos-_dziecka_szybko_skutecznie.png")',
                    }}
                  />
                  <span className="title">Zatkany nos u dziecka</span>
                </a>
                <a
                  href="/poradnik/dzieci/odpornosc_dziecka_-_zadbaj_i_zwiekszaj"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/iStock_63844397_LARGE.png")',
                    }}
                  />
                  <span className="title">
                    Odporność dziecka - zadbaj i zwiększaj
                  </span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px",
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a
                    id="ot-sdk-btn"
                    className="ot-sdk-show-settings"
                    rel="nofollow"
                  >
                    {" "}
                    Ustawienia plików Cookie{" "}
                  </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty.
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
